import React from 'react';
import { navigate } from 'gatsby';
import { CREATE_MEDIA_ROUTE, ALL_CARDS_ROUTE } from 'constants/navigation';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import LoyaltyInformationForm from 'components/loyalty-card/create-information';

const CreateInformationView = () => {
  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  return (
    <LoyaltyInformationForm
      saveForm={async (data) => {
        const getCreatePassService = await import('services/passes/create-pass-service');
        const { createLoyaltyPassInfo } = getCreatePassService.default({
          stateContext,
          noticationContext,
        });
        const createdPass = await createLoyaltyPassInfo({
          pass: {
            passType: 'loyalty-stamp',
            valueLabel: 'your membership number',
            barcodeDescription: 'Loyalty card',
            ...data,
          },
        });

        if (createdPass && createdPass.passId) {
          navigate(`${CREATE_MEDIA_ROUTE}?passId=${createdPass.passId}`);
          return true;
        } if (createdPass && createdPass.status && createdPass.status === 403) {
          navigate(ALL_CARDS_ROUTE);
          return true;
        }
        return false;
      }}/>
  );
};

export default CreateInformationView;
